"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var vue_1 = require("vue");
var vuex_1 = require("vuex");
var axios_1 = require("axios");
var prefectures_1 = require("./prefectures");
var contactTypes_1 = require("./contactTypes");
var validate_1 = require("../src-server/lib/validate");
var axios = axios_1.default.create({
    headers: {
        'Content-Type': 'application/json',
    },
});
vue_1.default.use(vuex_1.default);
var Progress;
(function (Progress) {
    Progress[Progress["INPUT"] = 0] = "INPUT";
    Progress[Progress["CONFIRM"] = 1] = "CONFIRM";
    Progress[Progress["COMPLETE"] = 2] = "COMPLETE";
})(Progress || (Progress = {}));
var prefix = '';
var registerAPI = '/register';
var validateFn = validate_1.validateContact;
if (location.pathname && location.pathname.indexOf('/resolve') === 0) {
    prefix = 'resolve_';
    registerAPI = '/register_resolve';
    validateFn = validate_1.validateResolve;
}
var progressName = [
    prefix + 'input',
    prefix + 'confirm',
    prefix + 'complete',
];
var queryParameters = location.search;
var smsCode = (function getSmsCode() {
    var queries = queryParameters
        .substring(1)
        .split('&')
        .reduce(function (obj, q) {
        var _a = q.split('='), key = _a[0], val = _a[1];
        obj[key] = val;
        return obj;
    }, {});
    /* tslint:disable:no-string-literal */
    return queries['sms_code'];
    /* tslint:enable:no-string-literal */
})();
var store = new vuex_1.default.Store({
    state: {
        progress: Progress.INPUT,
        loading: false,
        progressMap: progressName.map(function (name) { return name + '-form'; }),
        agreePrivacyPolicy: false,
        prefectures: prefectures_1.default,
        contactTypes: contactTypes_1.default,
        confirmMailAddr: '',
        caseNumber: '',
        phone1: '',
        phone2: '',
        phone3: '',
        // 検針票画像
        meterImage: null,
        meterImageURL: null,
        filename: '',
        /* 入力データ */
        contactData: {
            type: null,
            lname: '',
            fname: '',
            lnameKana: '',
            fnameKana: '',
            zipCode: '',
            pref: '',
            city: '',
            street: '',
            building: '',
            phone: '',
            mailAddr: '',
            content: '',
            smsCode: smsCode,
            file: null,
            supplypointSpecificNumber: '',
            prevRetailerName: '',
            prevRetailerContractNo: '',
        },
        errors: {
            type: '',
            subject: '',
            lname: '',
            fname: '',
            lnameKana: '',
            fnameKana: '',
            zipCode: '',
            pref: '',
            city: '',
            street: '',
            building: '',
            phone: '',
            mailAddr: '',
            content: '',
            unmatchMailAddr: '',
            supplypointSpecificNumber: '',
            prevRetailerName: '',
            prevRetailerContractNo: '',
        },
        responseErrors: [],
    },
    mutations: {
        progressNext: function (state) {
            document.documentElement.scrollTop = 0;
            state.progress++;
            var stateObj = {
                progress: state.progress,
            };
            var nextPath = '/' + progressName[state.progress] + queryParameters;
            history.pushState(stateObj, '', nextPath);
        },
        progressPrevious: function (state) {
            if (state.progress <= Progress.INPUT) {
                return;
            }
            history.back();
            document.documentElement.scrollTop = 0;
        },
        setType: function (state, value) {
            state.contactData.type = value;
        },
        setMeterImage: function (state, file) {
            state.filename = file.name;
            var reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = function () {
                var bodyData = reader.result;
                // base64部分を切り取る
                bodyData = bodyData.substr(bodyData.indexOf(',') + 1);
                state.contactData.file = {
                    name: file.name,
                    body: bodyData,
                };
                state.meterImage = file;
                state.meterImageURL = URL.createObjectURL(file);
            };
            reader.onerror = function (error) {
                console.log('Error: ', error);
            };
        },
        setLname: function (state, value) {
            state.contactData.lname = value;
        },
        setFname: function (state, value) {
            state.contactData.fname = value;
        },
        setLnameKana: function (state, value) {
            state.contactData.lnameKana = value;
        },
        setFnameKana: function (state, value) {
            state.contactData.fnameKana = value;
        },
        setZipCode: function (state, value) {
            state.contactData.zipCode = value;
        },
        setPref: function (state, value) {
            state.contactData.pref = store.getters.prefecturesReverse[value] || '';
        },
        setCity: function (state, value) {
            state.contactData.city = value;
        },
        setStreet: function (state, value) {
            state.contactData.street = value;
        },
        setBuilding: function (state, value) {
            state.contactData.building = value;
        },
        setPhone: function (state) {
            state.contactData.phone = [state.phone1, state.phone2, state.phone3].join('-');
        },
        setPhone1: function (state, value) {
            state.phone1 = value;
        },
        setPhone2: function (state, value) {
            state.phone2 = value;
        },
        setPhone3: function (state, value) {
            state.phone3 = value;
        },
        setMailAddr: function (state, value) {
            state.contactData.mailAddr = value;
        },
        setContent: function (state, value) {
            state.contactData.content = value;
        },
        setSupplypointSpecificNumber: function (state, value) {
            state.contactData.supplypointSpecificNumber = value;
        },
        setPrevRetailerName: function (state, value) {
            state.contactData.prevRetailerName = value;
        },
        setPrevRetailerContractNo: function (state, value) {
            state.contactData.prevRetailerContractNo = value;
        },
        setAgreePrivacyPolicy: function (state, value) {
            state.agreePrivacyPolicy = value;
        },
        setConfirmMailAddr: function (state, value) {
            state.confirmMailAddr = value;
        },
        setCaseNumber: function (state, value) {
            state.caseNumber = value;
        },
        setResponseErrors: function (state, errors) {
            state.responseErrors = errors;
        },
        setSMSCode: function (state, value) {
            state.contactData.smsCode = value;
        },
    },
    actions: {
        init: function (store) {
            var nextPath = "/" + progressName[0];
            if (store.state.contactData.smsCode) {
                nextPath += "?sms_code=" + store.state.contactData.smsCode;
            }
            history.replaceState({
                progress: Progress.INPUT,
            }, '', nextPath);
            window.addEventListener('popstate', function (ev) {
                if (ev.state && ev.state.progress === Progress.CONFIRM) {
                    location.reload();
                    return;
                }
                store.state.progress = ev.state
                    ? ev.state.progress || Progress.INPUT
                    : Progress.INPUT;
            });
        },
        resetErrors: function (_a) {
            var state = _a.state;
            Object.keys(state.errors).forEach(function (key) {
                state.errors[key] = '';
            });
        },
        checkInput: function (store) {
            store.dispatch('resetErrors');
            var state = store.state;
            // 第2引数:メールアドレスは必須
            var errors = validateFn(state.contactData);
            if (state.contactData.mailAddr !== state.confirmMailAddr) {
                errors.push({
                    name: 'unmatchMailAddr',
                    error: 'メールアドレスが一致していません',
                });
            }
            if (errors.length) {
                errors.forEach(function (err) {
                    var error = err.error, name = err.name;
                    state.errors[name] = error || '';
                });
                return false;
            }
            store.commit('progressNext');
            return true;
        },
        registerCase: function (store) {
            return tslib_1.__awaiter(this, void 0, void 0, function () {
                var recaptchaToken, body, res, data;
                return tslib_1.__generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            store.state.loading = true;
                            return [4 /*yield*/, new Promise(function (resolve) {
                                    grecaptcha.ready(function () {
                                        var sitekey = document.querySelector('#recaptcha-sitekey').value;
                                        grecaptcha.execute(sitekey, { action: 'homepage' }).then(resolve);
                                    });
                                })];
                        case 1:
                            recaptchaToken = _a.sent();
                            body = JSON.stringify(tslib_1.__assign(tslib_1.__assign({}, store.state.contactData), { subject: store.getters.typeStr, token: recaptchaToken }));
                            return [4 /*yield*/, axios.post(registerAPI, body).catch(function (e) {
                                    return {
                                        success: false,
                                        data: {
                                            errors: ['システムエラー。時間を置いて再度お試しください'],
                                        },
                                    };
                                })];
                        case 2:
                            res = _a.sent();
                            store.state.loading = false;
                            store.commit('setResponseErrors', res.data.errors);
                            if (!res.data.success) {
                                window.history.back();
                                return [2 /*return*/];
                            }
                            data = res.data;
                            store.commit('setCaseNumber', data.CaseNumber);
                            store.commit('progressNext');
                            return [2 /*return*/];
                    }
                });
            });
        },
    },
    getters: {
        currentProgress: function (state) {
            return state.progressMap[state.progress];
        },
        typeStr: function (state) {
            var type = state.contactData.type;
            if (type === null) {
                return '';
            }
            return state.contactTypes.find(function (ct) { return ct.value === type; }).label;
        },
        fullName: function (state) {
            return state.contactData.lname + ' ' + state.contactData.fname;
        },
        fullNameKana: function (state) {
            return state.contactData.lnameKana + ' ' + state.contactData.fnameKana;
        },
        fullAddr: function (state, _a) {
            var prefString = _a.prefString;
            var cd = state.contactData;
            return [prefString, cd.city, cd.street, ' ', cd.building].join('');
        },
        prefString: function (state) {
            if (!state.contactData.pref) {
                return '';
            }
            return state.prefectures[state.contactData.pref];
        },
        meterImageURL: function (state) {
            if (!state.meterImage) {
                return '';
            }
            state.meterImageURL = URL.createObjectURL(state.meterImage);
        },
        prefecturesReverse: function (state) {
            var prefs = state.prefectures;
            return Object.keys(prefs).reduce(function (base, key) {
                base[prefs[key]] = key;
                return base;
            }, {});
        },
    },
});
exports.default = store;
