"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var vue_property_decorator_1 = require("vue-property-decorator");
var vuex_1 = require("vuex");
var Input = /** @class */ (function (_super) {
    tslib_1.__extends(Input, _super);
    function Input() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Input.prototype.checkAgreePrivacyPolicy = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var that, ok, errorMsg;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.$store.commit('setPhone');
                        this.checkAddrs();
                        that = this;
                        if (!that.agreePrivacyPolicy) {
                            return [2 /*return*/];
                        }
                        return [4 /*yield*/, that.checkInput()];
                    case 1:
                        ok = _a.sent();
                        if (!ok) {
                            errorMsg = this.$refs.errorMsg;
                            $('html,body').animate({ scrollTop: errorMsg.offsetTop - 100 }, 500, 'swing');
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    /**
     * 郵便番号からの自動入力機能により住所が入力されたとき、更新のイベントが発火しないため、
     * 確認ボタン押下時にフォームの値を取り直す
     */
    Input.prototype.checkAddrs = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var that, refs;
            return tslib_1.__generator(this, function (_a) {
                that = this;
                refs = that.$refs;
                that.setPref(refs.pref.value);
                that.setCity(refs.city.value);
                that.setStreet(refs.street.value);
                return [2 /*return*/];
            });
        });
    };
    Input.prototype.delayCheckAddr = function () {
        var _this = this;
        setTimeout(function () {
            _this.checkAddrs();
        }, 500);
    };
    Object.defineProperty(Input.prototype, "existsErrors", {
        get: function () {
            return Object.values(this.errors).filter(Boolean);
        },
        enumerable: false,
        configurable: true
    });
    Input.prototype.mounted = function () {
        /**
         * 要素の出現のたび、郵便番号の自動取得機能のバインドを行う
         */
        new YubinBango.MicroformatDom(); // tslint:disable-line
    };
    Input = tslib_1.__decorate([
        vue_property_decorator_1.Component({
            computed: tslib_1.__assign(tslib_1.__assign({}, vuex_1.mapState([
                'agreePrivacyPolicy',
                'progress',
                'contactData',
                'contactTypes',
                'prefectures',
                'errors',
                'responseErrors',
                'confirmMailAddr',
                'phone1',
                'phone2',
                'phone3'
            ])), vuex_1.mapGetters([
                'prefString'
            ])),
            methods: tslib_1.__assign(tslib_1.__assign({}, vuex_1.mapMutations([
                'setAgreePrivacyPolicy',
                'setType',
                'setLname',
                'setFname',
                'setLnameKana',
                'setFnameKana',
                'setZipCode',
                'setPref',
                'setCity',
                'setStreet',
                'setBuilding',
                'setPhone1',
                'setPhone2',
                'setPhone3',
                'setMailAddr',
                'setContent',
                'setConfirmMailAddr'
            ])), vuex_1.mapActions([
                'checkInput'
            ]))
        })
    ], Input);
    return Input;
}(vue_property_decorator_1.Vue));
exports.default = Input;
