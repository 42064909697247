"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var vue_property_decorator_1 = require("vue-property-decorator");
var vuex_1 = require("vuex");
var Complete = /** @class */ (function (_super) {
    tslib_1.__extends(Complete, _super);
    function Complete() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Complete = tslib_1.__decorate([
        vue_property_decorator_1.Component({
            computed: tslib_1.__assign({}, vuex_1.mapState([
                'caseNumber'
            ]))
        })
    ], Complete);
    return Complete;
}(vue_property_decorator_1.Vue));
exports.default = Complete;
