"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = [
    { value: '2', label: '申込/契約' },
    { value: '1', label: '請求/支払' },
    { value: '5', label: 'Ponta関連' },
    { value: '6', label: 'クーポン/特典関連' },
    { value: '4', label: '会員ページ関連' },
    { value: '7', label: '電気設備関連' },
    { value: '3', label: '解約関連' },
    { value: '9', label: 'EVプラン関連' },
    { value: '8', label: 'その他' }
];
