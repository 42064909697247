"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.validateResolve = exports.validateContact = void 0;
var validator_1 = require("validator");
var wideKanaMatch = /^[ァ-ヶー]*$/;
/**
 * 都道府県コード 1-47
 */
var validPrefs = new Array(47).map(function (_, ind) {
    return ind + 1 + '';
});
/** <項目名,バリデーション関数> */
var validates = {
    type: function (v) {
        if (!v) {
            return '問い合わせ種別を選択してください';
        }
        if (!validator_1.default.isIn(v, [1, 2, 3, 4, 5, 6, 7, 8, 9])) {
            return '問い合わせ種別が不正な値です';
        }
    },
    lname: function (v) {
        if (validator_1.default.isEmpty(v)) {
            return 'お名前(姓)を入力してください';
        }
        if (!validator_1.default.isLength(v, {
            max: 39
        })) {
            return 'お名前(姓)が長すぎます';
        }
    },
    fname: function (v) {
        if (validator_1.default.isEmpty(v)) {
            return 'お名前(名)を入力してください';
        }
        if (!validator_1.default.isLength(v, {
            max: 39
        })) {
            return 'お名前(名)が長すぎます';
        }
    },
    lnameKana: function (v) {
        if (validator_1.default.isEmpty(v)) {
            return 'お名前カナ(姓)を入力してください';
        }
        if (!wideKanaMatch.test(v)) {
            return 'お名前カナ(姓)は全角カタカナで入力してください';
        }
        if (!validator_1.default.isLength(v, {
            max: 39
        })) {
            return 'お名前カナ(姓)が長すぎます';
        }
    },
    fnameKana: function (v) {
        if (validator_1.default.isEmpty(v)) {
            return 'お名前カナ(名)を入力してください';
        }
        if (!wideKanaMatch.test(v)) {
            return 'お名前カナ(名)は全角カタカナで入力してください';
        }
        if (!validator_1.default.isLength(v, {
            max: 39
        })) {
            return 'お名前カナ(名)が長すぎます';
        }
    },
    zipCode: function (v) {
        if (validator_1.default.isEmpty(v)) {
            return '郵便番号を入力してください';
        }
        if (!validator_1.default.isPostalCode(v, 'JP')) {
            return '郵便番号が不正です';
        }
    },
    pref: function (v) {
        if (validator_1.default.isEmpty(v)) {
            return '都道府県を選択してください';
        }
        if (validator_1.default.isIn(v, validPrefs)) {
            return '不正な都道府県です';
        }
    },
    city: function (v) {
        if (validator_1.default.isEmpty(v)) {
            return '市区町村を入力してください';
        }
        if (!validator_1.default.isLength(v, { max: 255 })) {
            return '市区町村が長すぎます';
        }
    },
    street: function (v) {
        if (validator_1.default.isEmpty(v)) {
            return '番地丁目を入力してください';
        }
        if (!validator_1.default.isLength(v, { max: 255 })) {
            return '番地丁目が長すぎます';
        }
    },
    building: function (v) {
        if (validator_1.default.isEmpty(v)) {
            return;
        }
        if (!validator_1.default.isLength(v, { max: 255 })) {
            return '建物名が長すぎます';
        }
    },
    phone: function (v) {
        if (validator_1.default.isEmpty(v)) {
            return '電話番号を入力してください';
        }
        if (!/^\d{2,6}-\d{2,4}-\d{2,4}$/.test(v)) {
            return '電話番号が不正です';
        }
    },
    // メールアドレス(必須)
    mailAddr: function (v) {
        if (validator_1.default.isEmpty(v)) {
            return 'メールアドレスを入力してください';
        }
        if (!validator_1.default.isEmail(v)) {
            return 'メールアドレスが不正です';
        }
    },
    // メールアドレス(任意)
    mailAddrForDeficiency: function (v) {
        if (validator_1.default.isEmpty(v)) {
            return;
        }
        if (!validator_1.default.isEmail(v)) {
            return 'メールアドレスが不正です';
        }
    },
    content: function (v) {
        if (validator_1.default.isEmpty(v)) {
            return '問い合わせ内容を入力してください';
        }
        if (!validator_1.default.isLength(v, { min: 1, max: 1000 })) {
            return '問い合わせ内容が長すぎます';
        }
    },
    supplypointSpecificNumber: function (v) {
        if (validator_1.default.isEmpty(v)) {
            return;
        }
        if (!/^\d{22}$/.test(v)) {
            return '半角数値のみ22桁で入力ください';
        }
    },
    prevRetailerName: function (v) {
        if (validator_1.default.isEmpty(v)) {
            return '現在の電力会社を入力してください';
        }
    },
    prevRetailerContractNo: function (v) {
        if (validator_1.default.isEmpty(v)) {
            return '現在の電力会社での契約番号を入力してください';
        }
    }
};
var ContactCheckParamList = {
    type: validates.type,
    lname: validates.lname,
    fname: validates.fname,
    lnameKana: validates.lnameKana,
    fnameKana: validates.fnameKana,
    zipCode: validates.zipCode,
    pref: validates.pref,
    city: validates.city,
    street: validates.street,
    building: validates.building,
    phone: validates.phone,
    mailAddr: validates.mailAddr,
    content: validates.content,
};
var ResolveCheckParamList = {
    lname: validates.lname,
    fname: validates.fname,
    lnameKana: validates.lnameKana,
    fnameKana: validates.fnameKana,
    zipCode: validates.zipCode,
    pref: validates.pref,
    city: validates.city,
    street: validates.street,
    building: validates.building,
    phone: validates.phone,
    mailAddr: validates.mailAddrForDeficiency,
    supplypointSpecificNumber: validates.supplypointSpecificNumber,
    prevRetailerName: validates.prevRetailerName,
    prevRetailerContractNo: validates.prevRetailerContractNo,
};
function validateContact(param) {
    return validateFields(param, ContactCheckParamList);
}
exports.validateContact = validateContact;
function validateResolve(param) {
    return validateFields(param, ResolveCheckParamList);
}
exports.validateResolve = validateResolve;
/**
 * validateFields
 * 入力データオブジェクトの各項目をバリデーション
 */
function validateFields(param, checks) {
    var checkResults = Object.keys(checks).map(function (name) {
        var v = param[name];
        var validate = checks[name];
        var errMsg = validate(v);
        if (errMsg) {
            /* エラーがある場合フィールド名とエラー内容を詰める */
            return {
                name: name,
                error: errMsg
            };
        }
    }).filter(Boolean);
    return checkResults;
}
