"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
require("babel-polyfill");
var vue_1 = require("vue");
var App_vue_1 = require("./App.vue");
var store_1 = require("./store");
vue_1.default.config.productionTip = false;
new vue_1.default({
    store: store_1.default,
    render: function (h) { return h(App_vue_1.default); },
}).$mount('.contact-content');
