"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var vue_property_decorator_1 = require("vue-property-decorator");
var vuex_1 = require("vuex");
var Confirm = /** @class */ (function (_super) {
    tslib_1.__extends(Confirm, _super);
    function Confirm() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Confirm.prototype.send = function () {
        if (this.loading) {
            return;
        }
        this.registerCase();
    };
    Confirm.prototype.back = function () {
        this.$store.commit('progressPrevious');
    };
    Confirm = tslib_1.__decorate([
        vue_property_decorator_1.Component({
            computed: tslib_1.__assign(tslib_1.__assign({}, vuex_1.mapState([
                'loading',
                'contactData',
            ])), vuex_1.mapGetters([
                'typeStr',
                'fullName',
                'fullNameKana',
                'fullAddr'
            ])),
            methods: tslib_1.__assign({}, vuex_1.mapActions([
                'registerCase'
            ]))
        })
    ], Confirm);
    return Confirm;
}(vue_property_decorator_1.Vue));
exports.default = Confirm;
