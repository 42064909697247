"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var vue_property_decorator_1 = require("vue-property-decorator");
var vuex_1 = require("vuex");
var Input_vue_1 = require("./components/Input.vue");
var Confirm_vue_1 = require("./components/Confirm.vue");
var Complete_vue_1 = require("./components/Complete.vue");
var App = /** @class */ (function (_super) {
    tslib_1.__extends(App, _super);
    function App() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    App.prototype.created = function () {
        this.$store.dispatch('init');
    };
    App = tslib_1.__decorate([
        vue_property_decorator_1.Component({
            components: {
                InputForm: Input_vue_1.default,
                ConfirmForm: Confirm_vue_1.default,
                CompleteForm: Complete_vue_1.default
            },
            computed: tslib_1.__assign(tslib_1.__assign({}, vuex_1.mapState([
                'progress'
            ])), vuex_1.mapGetters([
                'currentProgress'
            ])),
            methods: tslib_1.__assign({}, vuex_1.mapMutations([
                'setAgreePrivacyPolicy',
                'setType',
                'progressNext'
            ]))
        })
    ], App);
    return App;
}(vue_property_decorator_1.Vue));
exports.default = App;
